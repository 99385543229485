import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useLocation } from 'react-router-dom';
export default function DocumentViewer() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fileName = queryParams.get('name');
    const filePath = queryParams.get('path');
    const docs = [
        { uri: filePath, fileName },
    ];
    return <div>
        <DocViewer documents={docs} 
        pluginRenderers={DocViewerRenderers}
         style={{ width: window.innerWidth,height:window.innerHeight }} />
    </div>;
}